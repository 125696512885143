
import React, { useState, useEffect } from 'react';
import { BsBoxArrowRight, BsPersonCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import A1 from '../Images/logo-w.png';

const AdminDashboard = () => {
    const [usersData, setUsersData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const [permissions, setPermissions] = useState({
        add: false,
        edit: false,
        view: false,
        clearScreen: false,
        save: false,
        approve: false,
        print: false,
        close: false,
    });


    const fetchUsersData = async () => {
        const response = await fetch('https://erpapi.emedha.in/pages/api/user');
        const data = await response.json();
        setUsersData(data.users);
    };


    useEffect(() => {
        fetchUsersData();
    }, []);

    const handleCheckboxChange = (e) => {
        setPermissions({
            ...permissions,
            [e.target.name]: e.target.checked,
        });
    };

    const handleOpenModal = (user) => {
        setSelectedUser(user);

        setPermissions({
            add: user.add_permission === 1,
            edit: user.edit_permission === 1,
            view: user.view_permission === 1,
            clearScreen: user.clear_screen_permission === 1,
            save: user.save_permission === 1,
            approve: user.approve_permission === 1,
            print: user.print_permission === 1,
            close: user.close_permission === 1,
        });
        fetchUsersData();
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSavePermissions = async () => {
        if (!selectedUser || !selectedUser.id) {
            alert('No user selected or user ID is missing');
            return;
        }

        const permissionsData = {
            add: permissions.add ? 1 : 0,
            edit: permissions.edit ? 1 : 0,
            view: permissions.view ? 1 : 0,
            clearScreen: permissions.clearScreen ? 1 : 0,
            save: permissions.save ? 1 : 0,
            approve: permissions.approve ? 1 : 0,
            print: permissions.print ? 1 : 0,
            close: permissions.close ? 1 : 0,
        };

        console.log('Permissions Data:', permissionsData);

        try {
            const response = await fetch(`https://erpapi.emedha.in/pages/api/permissions/${selectedUser.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(permissionsData),
            });
            const data = await response.json();
            if (response.ok) {
                alert('Permissions updated successfully');
                fetchUsersData();
                handleCloseModal();
            } else {
                alert('Error updating permissions: ' + (data.message || 'Unknown error'));
            }
        } catch (error) {
            alert('An error occurred while updating permissions.');
        }
    };

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleLogout = () => {
        sessionStorage.removeItem('userData');
        sessionStorage.removeItem('userType');
        navigate('/');
        console.log('Logging out...');
        setDropdownVisible(false);
    };



    return (

        <>

            <div style={{ backgroundColor: "#dbc4a9" }}>
                <div className="container d-flex justify-content-between align-items-center">
                    <img src={A1} alt="item" style={{ width: "20%" }} />
                    <h1 className='mb-4 mt-4' style={{ color: "darkblue", fontSize: "1.95rem", fontWeight: "1000", marginBottom: "1rem" }}>ADMIN DASHBOARD</h1>
                    <div className="position-relative">
                        <BsPersonCircle size={30} style={{ cursor: 'pointer' }} onClick={toggleDropdown} />
                        {dropdownVisible && (
                            <div style={{ position: 'absolute', right: '20px', top: '100%', backgroundColor: '#fff', zIndex: 1000, borderRadius: '5px', boxShadow: '0 2px 5px rgba(0,0,0,0.2)' }}>
                                <button
                                    onClick={handleLogout}
                                    style={{ backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', padding: '10px 15px', cursor: 'pointer', transition: 'background-color 0.3s', display: 'flex', alignItems: 'center' }}
                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
                                >
                                    <BsBoxArrowRight style={{ marginRight: '5px' }} />
                                    Logout
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="container mt-5" >

                <h2 className="mb-4 text-center">All USERS DETAILS</h2>

                {usersData.length > 0 && (
                    <table className="table table-striped table-bordered table-hover">
                        <thead className="table-dark">
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Item Data</th>
                                <th>Supplier Data</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usersData.map((user, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{user.User_nm}</td>
                                    <td>{user.Phone_no}</td>
                                    <td>{user.email_id}</td>
                                    <td>{user.item_mst === "1" ? "Yes" : "No"}</td>
                                    <td>{user.supp_mst === "1" ? "Yes" : "No"}</td>

                                    <td>
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={() => handleOpenModal(user)}>
                                            Permissions
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                {showModal && selectedUser && (
                    <div className="modal show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Assign Permissions to {selectedUser.User_nm}</h5>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" name="add" checked={permissions.add} onChange={handleCheckboxChange} />
                                                    <label className="form-check-label">Add</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" name="edit" checked={permissions.edit} onChange={handleCheckboxChange} />
                                                    <label className="form-check-label">Edit</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" name="view" checked={permissions.view} onChange={handleCheckboxChange} />
                                                    <label className="form-check-label">View</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" name="clearScreen" checked={permissions.clearScreen} onChange={handleCheckboxChange} />
                                                    <label className="form-check-label">Clear Screen</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" name="save" checked={permissions.save} onChange={handleCheckboxChange} />
                                                    <label className="form-check-label">Save</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" name="approve" checked={permissions.approve} onChange={handleCheckboxChange} />
                                                    <label className="form-check-label">Approve</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" name="print" checked={permissions.print} onChange={handleCheckboxChange} />
                                                    <label className="form-check-label">Print</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" name="close" checked={permissions.close} onChange={handleCheckboxChange} />
                                                    <label className="form-check-label">Close</label>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary btn-sm" onClick={handleSavePermissions}>
                                        Save Permissions
                                    </button>
                                    <button type="button" className="btn btn-secondary btn-sm" onClick={handleCloseModal}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default AdminDashboard;
