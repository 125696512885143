import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './App.css';
import Login from './Components/Auth/Login';
import Register from './Components/Auth/Register';
import Suppliermst from './Components/Pages/Suppliermst';
import ItemMasterForm from './Components/Pages/ItemMasterForm';
import PurchaseOrder from './Components/Pages/PurchaseOrder';
import Dashboard from './Components/Pages/Dashboard';
import AdminDashboard from './Components/Pages/AdminDashboard';
import Indent from './Components/Pages/Indent';



function App() {

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/suppliermasterform-suppliermasterform' element={<Suppliermst />} />
        <Route path='/itemmasterform-itemmasterform' element={<ItemMasterForm />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/admindashboard-admindashboard' element={<AdminDashboard />} />
        <Route path='/purchaseindentform-purchaseindentform' element={<Indent />} />
        <Route path='/purchaseorder-purchaseorder' element={<PurchaseOrder />} />
      </Routes>
    </Router>
  );
}

export default App;
