
// import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import A1 from '../Images/logo-w.png';

// const Login = () => {
//   const [email_id, setEmailId] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const loginData = { email_id, password };

//     try {
//       if (email_id === 'admin.erp@gmail.com' && password === 'admin') {
//         const userData = { email: email_id, name: 'Admin' };
//         const token = 'mock-token-for-admin';
//         sessionStorage.setItem('user', JSON.stringify(userData));
//         sessionStorage.setItem('authToken', token);
//         setSuccess('Login successful!');
//         setError('');
//         navigate('/admindashboard-admindashboard'); // Admin dashboard
//       } else {
//         const response = await fetch('https://erpapi.emedha.in/auth/api/login', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(loginData),
//         });
//         const result = await response.json();
//         if (response.ok) {
//           const userData = result.user; 
//           const token = result.token;
//           sessionStorage.setItem('user', JSON.stringify(userData));
//           sessionStorage.setItem('authToken', token);
//           if (userData) {
//             navigate('/dashboard');
//           }
//           setSuccess(result.message);
//           setError('');
//         } else {
//           setError(result.message);
//           setSuccess('');
//         }
//       }
//     } catch (err) {
//       setError('There was an error logging in. Please try again.');
//     }
//   };


//   return (
//     <div className="container d-flex justify-content-center align-items-center min-vh-100">
//       <div className="form-box text-center p-4 rounded shadow-sm" style={{ maxWidth: '400px', width: '100%', backgroundColor: '#dbc4a9' }}>
//         <img src={A1} alt="item" style={{ width: "60%" }} />
//         <h1 className="text-primary mt-3 mb-4">LOGIN</h1>
//         <form onSubmit={handleSubmit}>
//           <div className="mb-3 position-relative">
//             <input type="email" className="form-control" placeholder="Email" value={email_id} onChange={(e) => setEmailId(e.target.value)} required />
//           </div>
//           <div className="mb-3 position-relative">
//             <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
//           </div>

//           {error && <div className="text-danger mb-3">{error}</div>}

//           <div className="forgot-link mb-3">
//             <Link to="/register" style={{ color: 'black' }}>Register</Link>
//           </div>

//           <button type="submit" className="btn btn-primary w-100 py-2 mb-3">Login</button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Login;


import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import A1 from '../Images/logo-w.png';

const Login = () => {
  const [email_id, setEmailId] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const loginData = { email_id, password };
    if (email_id === 'admin.erp@gmail.com' && password === 'admin') {
      const userData = { email: email_id, name: 'Admin', role: 'admin' };
      const token = 'mock-token-for-admin'; 
      sessionStorage.setItem('user', JSON.stringify(userData));
      sessionStorage.setItem('authToken', token);

      setSuccess('Login successful!');
      setError('');
      navigate('/admindashboard-admindashboard'); 
      return; 
    }

    try {
      const response = await fetch('https://erpapi.emedha.in/auth/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });

      const result = await response.json();

      if (response.ok) {
        const userData = result.user;
        const token = result.token; 
        sessionStorage.setItem('user', JSON.stringify(userData));
        sessionStorage.setItem('authToken', token);
        setSuccess(result.message); 
        setError(''); 
        if (userData.role === 'admin') {
          navigate('/admindashboard-admindashboard'); 
        } else {
          navigate('/dashboard'); 
        }
      } else {
        setError(result.message);
        setSuccess('');
      }
    } catch (err) {
      setError('There was an error logging in. Please try again.');
      setSuccess('');
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100">
      <div className="form-box text-center p-4 rounded shadow-sm" style={{ maxWidth: '400px', width: '100%', backgroundColor: '#dbc4a9' }}>
        <img src={A1} alt="item" style={{ width: "60%" }} />
        <h1 className="text-primary mt-3 mb-4">LOGIN</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-3 position-relative">
            <input 
              type="email" 
              className="form-control" 
              placeholder="Email" 
              value={email_id} 
              onChange={(e) => setEmailId(e.target.value)} 
              required 
            />
          </div>
          <div className="mb-3 position-relative">
            <input 
              type="password" 
              className="form-control" 
              placeholder="Password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
            />
          </div>

          {error && <div className="text-danger mb-3">{error}</div>}

          <div className="forgot-link mb-3">
            <Link to="/register" style={{ color: 'black' }}>Register</Link>
          </div>

          <button type="submit" className="btn btn-primary w-100 py-2 mb-3">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
