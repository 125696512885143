import React, { useEffect, useState } from 'react';
import { FaCheck, FaPlus, FaSave } from 'react-icons/fa';
import { HiOutlineRefresh } from 'react-icons/hi';
import { IoPrint } from 'react-icons/io5';
import { LuView } from 'react-icons/lu';
import { MdEdit } from 'react-icons/md';
import { RiCloseLargeFill } from 'react-icons/ri';
import A1 from '../Images/logo-w.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


const PurchaseOrder = () => {
  const [selectedIndents, setSelectedIndents] = useState([]);
  const [selectedIndentss, setSelectedIndentss] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDataVisible, setIsDataVisible] = useState(false);
  const user = JSON.parse(sessionStorage.getItem('user'));
  const id = user ? user.id : null;
  const userName = user ? user.User_nm : 'Guest';
  const [suppliers, setSuppliers] = useState([]);
  const [Supp_id, setSelectedSupplier] = useState('');
  const [states, setStates] = useState({});
  const [Credit_days, setCreditDays] = useState('');
  const [stateMaster, setStateMaster] = useState({});
  const [Des_gst, setDesGst] = useState('');
  const [Des_state_cd, setDesState] = useState('');
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showEditformm, setShowEditFormm] = useState(false);
  const [PO_no, setPO_no] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [poData, setPoData] = useState(null);
  const [poNumbers, setPoNumbers] = useState([]);
  const [Po_Type, setPoType] = useState('CAPX');
  const [Deli_terms, setDeliTerms] = useState('');
  const [Freight_amt, setFreightAmt] = useState('');
  const [Insurance_amt, setInsuranceAmt] = useState('');
  const [Oth_amt, setOthAmt] = useState('');
  const [Item_type, setItemType] = useState('Import');
  const [Pay_terms, setPayTerms] = useState('');
  const [Total_amt, setTotal_Amt] = useState('');
  const [items, setItems] = useState('');
  const [Gross_amt, setGross_amt] = useState('');
  const [Disc_amt, setDisc_amt] = useState('');
  const [Taxable_amt, setTaxable_amt] = useState('');
  const [Net_amt, setNet_amt] = useState('');



  useEffect(() => {
    axios.get('https://erpapi.emedha.in/pages/api/api/suppliers')
      .then((response) => {
        setSuppliers(response.data);
      })
      .catch((error) => {
        console.error('Error fetching suppliers:', error);
      });
    axios.get('https://erpapi.emedha.in/pages/api/api/states')
      .then((response) => {
        setStates(response.data);
        setStateMaster(response.data);
      })
      .catch((error) => {
        console.error('Error fetching state master:', error);
      });
    axios.get('https://erpapi.emedha.in/pages/api/api/items-indents')
      .then((response) => {
        setItems(response.data)
      })
      .catch((error) => {
        console.error('Error fetching state master:', error);
      });
  }, []);
  const handleSupplierChange = (event) => {
    const supplierCd = event.target.value;
    setSelectedSupplier(supplierCd);
    const selectedSupplierData = suppliers.find(s => s.Supp_cd.toString() === supplierCd.toString());
    setCreditDays(selectedSupplierData ? selectedSupplierData.Credit_Days : '');
  };
  const handleCreditDaysChange = (event) => {
    setCreditDays(event.target.value);
  };
  const supplier = suppliers.find(s => s.Supp_cd.toString() === Supp_id.toString());
  const extractStateFromGST = (gst) => {
    if (!gst) return '';
    const stateCode = gst.substring(0, 2);
    const stateName = states[stateCode] || 'Unknown State';
    return stateName;
  };
  const handleDesGstChange = (e) => {
    const gstValue = e.target.value;
    if (gstValue.length <= 12) {
      setDesGst(gstValue);
      if (gstValue.length >= 2) {
        const stateCode = gstValue.substring(0, 2);
        const stateName = stateMaster[stateCode] || 'Unknown State';
        setDesState(stateName);
      } else {
        setDesState('');
      }
    }
  };

  const [deliveryDate, setDeliveryDate] = useState('');
  const [PO_Dt, setPoDate] = useState('');
  useEffect(() => {
    const currentDate = new Date().toISOString().split('T')[0];
    setDeliveryDate(currentDate);
    setPoDate(currentDate);
  }, []);

  const [indents, setIndents] = useState([]);
  useEffect(() => {
    const fetchPendingIndents = async () => {
      try {
        const response = await fetch('https://erpapi.emedha.in/pages/api/api/pending-indents');
        const data = await response.json();
        setIndents(data);
      } catch (error) {
        console.error('Error fetching pending indents:', error);
      }
    };

    fetchPendingIndents();
  }, []);
  const [combinedData, setCombinedData] = useState([]);


  useEffect(() => {
    const fetchPONumbers = async () => {
      try {
        const response = await axios.get('https://erpapi.emedha.in/pages/api/api/po-numbers');
        setPoNumbers(response.data);
      } catch (err) {
        console.error("Error fetching PO numbers", err);
      }
    };
    fetchPONumbers();
  }, []);

  const handlePOSelectChangee = (event) => {
    setPO_no(event.target.value);
    setPoData(null);
    setIsEditing(false);
  };

  const netAmount = parseFloat(Net_amt) || 0;

  useEffect(() => {
    if (items.length > 0 && indents.length > 0) {
      const combined = indents.map((indent) => {
        const item = items.find(item => item.itemCd === indent.itemCd);

        return {
          ...indent,
          itemDesc: item ? item.itemDesc : 'N/A',
          itemUOM: item ? item.itemUOM : 'N/A',
          Supp_Nm: item ? item.Supp_Nm : 'N/A',
          Indent_dt: item ? item.Indent_dt : 'N/A',
          Remarks: item ? item.Remarks : 'N/A',
          Location_Desc: item ? item.Location_Desc : 'N/A',
          taxRate: item ? item.taxRate : 'N/A',
          hsnCode: item ? item.hsnCode : 'N/A',
          qty: item ? item.qty : 'N/A',
          Location_id: item ? item.Location_id : 'N/A',
        };
      });
      setCombinedData(combined);
    }
  }, [items, indents]);

  const handleIndentSelection = (indent_no, index) => {
    const key = `${indent_no}-${index}`;
    setSelectedIndents((prev) => {
      if (prev.includes(key)) {
        return prev.filter((id) => id !== key);
      }
      return [...prev, key];
    });
  };

  const handleIndentAdd = () => { 
    console.log('Selected Indents:', selectedIndents);
    setIsDataVisible(true);
  };


  const calculateGrossAmt = (poQty, itemRate) => { return poQty * itemRate; };
  const calculateDiscAmt = (grossAmt, discPerc) => { return (grossAmt * discPerc) / 100; };
  const calculateTaxableAmt = (grossAmt, discAmt) => { return grossAmt - discAmt; };

  const handleInputChange = (e, indent_no, field, index) => {
    if (!Supp_id) { alert('Please select a supplier before entering the PO quantity.'); return; }

    const value = e.target.value;
    setCombinedData(prevIndents =>
      prevIndents.map((indent, i) => {
        if (indent.indent_no === indent_no && i === index) {
          const updatedIndent = { ...indent, [field]: value };
          const { cgstPer, sgstPer, igstPer } = calculateTaxes(indent.taxRate, Des_state_cd, extractStateFromGST(supplier.Gst_No));
          updatedIndent.sgstPer = sgstPer;
          updatedIndent.cgstPer = cgstPer;
          updatedIndent.igstPer = igstPer;
          const grossAmt = calculateGrossAmt(updatedIndent.PO_qty, updatedIndent.Item_rate);
          const discAmt = calculateDiscAmt(grossAmt, updatedIndent.Disc_per);
          const taxableAmt = calculateTaxableAmt(grossAmt, discAmt);
          updatedIndent.sgstAmt = (sgstPer * taxableAmt) / 100;
          updatedIndent.cgstAmt = (cgstPer * taxableAmt) / 100;
          updatedIndent.igstAmt = (igstPer * taxableAmt) / 100;
          calculateTotalAmountAndDetails();
          return updatedIndent;
        }
        return indent;
      })
    );
  };

  const calculateTaxes = (gstPer, desStateCd, suppStateCd) => {
    let cgstPer = 0;
    let sgstPer = 0;
    let igstPer = 0;
    if (gstPer === undefined || gstPer === null || isNaN(gstPer)) { return { cgstPer, sgstPer, igstPer }; }
    if (desStateCd === suppStateCd) { cgstPer = gstPer / 2; sgstPer = gstPer / 2; } else { igstPer = gstPer; }
    return { cgstPer, sgstPer, igstPer };
  };

  const calculateTotalAmountAndDetails = () => {
    const grossAmt = combinedData
      .filter((indent, index) => selectedIndents.includes(`${indent.indent_no}-${index}`))
      .reduce((sum, indent) => {
        const qty = parseFloat(indent.PO_qty);
        const rate = parseFloat(indent.Item_rate);
        const itemGrossAmt = calculateGrossAmt(qty, rate);
        return sum + itemGrossAmt;
      }, 0);
    const discAmt = combinedData
      .filter((indent, index) => selectedIndents.includes(`${indent.indent_no}-${index}`))
      .reduce((sum, indent) => {
        const grossAmt = calculateGrossAmt(parseFloat(indent.PO_qty), parseFloat(indent.Item_rate));
        const itemDiscAmt = calculateDiscAmt(grossAmt, parseFloat(indent.Disc_per));
        return sum + itemDiscAmt;
      }, 0);
    const taxableAmt = combinedData
      .filter((indent, index) => selectedIndents.includes(`${indent.indent_no}-${index}`))
      .reduce((sum, indent) => {
        const grossAmt = calculateGrossAmt(parseFloat(indent.PO_qty), parseFloat(indent.Item_rate));
        const discAmt = calculateDiscAmt(grossAmt, parseFloat(indent.Disc_per));
        const itemTaxableAmt = calculateTaxableAmt(grossAmt, discAmt);
        return sum + itemTaxableAmt;
      }, 0);
    const Net_amt = combinedData
      .filter((indent, index) => { const key = `${indent.indent_no}-${index}`; return selectedIndents.includes(key); })
      .reduce((sum, indent) => {
        const grossAmt = calculateGrossAmt(indent.PO_qty, indent.Item_rate);
        const discAmt = calculateDiscAmt(grossAmt, indent.Disc_per);
        const taxableAmt = calculateTaxableAmt(grossAmt, discAmt);
        const sgstAmt = (parseFloat(indent.sgstPer) * taxableAmt) / 100;
        const cgstAmt = (parseFloat(indent.cgstPer) * taxableAmt) / 100;
        const igstAmt = (parseFloat(indent.igstPer) * taxableAmt) / 100;
        return sum + (taxableAmt + sgstAmt + cgstAmt + igstAmt);
      }, 0);
    const Total_amt = Net_amt + parseFloat(Freight_amt || 0) + parseFloat(Insurance_amt || 0) + parseFloat(Oth_amt || 0);
    setTotal_Amt(Total_amt);
    return { grossAmt, discAmt, taxableAmt, Net_amt, Total_amt };
  };


  const handleSave = async () => {

    if (selectedIndents.length === 0) { alert('Please select at least one indent before saving.'); return; }
    const missingFields = []; combinedData.forEach((indent, index) => { if (selectedIndents.includes(`${indent.indent_no}-${index}`)) { if (!indent.PO_qty || !indent.Item_rate || !indent.Disc_per) { missingFields.push(`Indent No: ${indent.indent_no}`); } } });
    if (missingFields.length > 0) { alert(`Please fill in the missing fields for the following indents: ${missingFields.join(', ')}`); return; }

    const { grossAmt, discAmt, taxableAmt, Net_amt, Total_amt } = calculateTotalAmountAndDetails();

    const poHeaderData = {
      PO_Dt: PO_Dt,
      Po_Type: Po_Type,
      Item_type: Item_type,
      Deli_terms: Deli_terms,
      Deli_dt: deliveryDate,
      Pay_terms: Pay_terms,
      Credit_days: Credit_days,
      Des_gst: Des_gst || '',
      Des_state_cd: Des_gst ? Des_gst.slice(0, 2) : '',
      Supp_id: Supp_id,
      Supp_gst: suppliers.find(s => s.Supp_cd.toString() === Supp_id.toString())?.Gst_No || '',
      Supp_state_cd: suppliers.find(s => s.Supp_cd.toString() === Supp_id.toString())?.Gst_No.slice(0, 2) || '',
      Gross_amt: grossAmt,
      Disc_amt: discAmt,
      Taxable_amt: taxableAmt,
      Net_amt: Net_amt,
      Freight_amt: Freight_amt,
      Insurance_amt: Insurance_amt,
      Oth_amt: Oth_amt,
      Total_amt: Total_amt,
    };

    const poDtlData = combinedData
      .filter((indent, index) => selectedIndents.includes(`${indent.indent_no}-${index}`))
      .map((indent, index) => {
        const grossAmt = calculateGrossAmt(indent.PO_qty, indent.Item_rate);
        const discAmt = calculateDiscAmt(grossAmt, indent.Disc_per);
        const taxableAmt = calculateTaxableAmt(grossAmt, discAmt);
        const supplier = suppliers.find(s => s.Supp_cd.toString() === Supp_id.toString());
        const supplierGstNo = supplier?.Gst_No || '';
        const { cgstPer, sgstPer, igstPer } = calculateTaxes(indent.taxRate, Des_state_cd, extractStateFromGST(supplierGstNo));

        return {
          Sl_no: indent.Sl_no,
          itemCd: indent.itemCd,
          UOM: indent.itemUOM,
          Hsn_code: indent.hsnCode,
          GST_per: indent.taxRate,
          Indent_loc: indent.Location_id,
          Indent_no: indent.indent_no,
          qty: indent.qty,
          PO_qty: indent.PO_qty,
          Item_rate: indent.Item_rate,
          Gross_amt: grossAmt,
          PO_DTL_Disc_per: indent.Disc_per,
          Disc_amt: discAmt,
          Taxable_amt: taxableAmt,
          Sgst_per: sgstPer,
          Sgst_amt: (sgstPer * taxableAmt) / 100,
          Cgst_per: cgstPer,
          Cgst_amt: (cgstPer * taxableAmt) / 100,
          Igst_per: igstPer,
          Igst_amt: (igstPer * taxableAmt) / 100,
          Ugst_per: 0,
          Ugst_amt: 0,
          Net_amt: (calculateTaxableAmt(calculateGrossAmt(indent.PO_qty, indent.Item_rate), calculateDiscAmt(calculateGrossAmt(indent.PO_qty, indent.Item_rate), indent.Disc_per)) + indent.sgstAmt + indent.cgstAmt + indent.igstAmt).toFixed(2)
        };
      });

    const requestData = { PO_HDR: poHeaderData, PO_DTL: poDtlData };

    if (isEditing) {
      try {
        await axios.put(`https://erpapi.emedha.in/pages/api/API/PURCHASE_ORDER/${PO_no}`, requestData);
        alert('Purchase Order updated successfully!');
      } catch (error) {
        alert('Failed to update purchase order. Please try again.');
      }
    } else {
      try {
        const response = await axios.post('https://erpapi.emedha.in/pages/api/PURCHASE_ORDER', requestData);
        console.log('Response from backend:', response);
        alert('Purchase Order added successfully!');
      } catch (error) {
        console.error('Error adding indent:', error);
        alert('Failed to add purchase order. Please try again.');
      }
    }
  };


  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setPO_no(searchTerm);
    setPoData(null);
    setIsEditing(false);
    if (searchTerm) {
      fetchPOData(searchTerm);
    }
  };

  const fetchPOData = async () => {
    if (!PO_no) return;

    try {
      const response = await axios.get(`https://erpapi.emedha.in/pages/api/PURCHASE_ORDER/${PO_no}`);
      const fetchedData = response.data;
      setPoData(fetchedData);
      setPoType(fetchedData.PO_HDR.Po_Type);
      setItemType(fetchedData.PO_HDR.Item_type);
      setDeliTerms(fetchedData.PO_HDR.Deli_terms);
      setPayTerms(fetchedData.PO_HDR.Pay_terms);
      setCreditDays(fetchedData.PO_HDR.Credit_days);
      setDesGst(fetchedData.PO_HDR.Des_gst);
      setDesState(fetchedData.PO_HDR.Des_state_cd);
      setFreightAmt(fetchedData.PO_HDR.Freight_amt);
      setInsuranceAmt(fetchedData.PO_HDR.Insurance_amt);
      setOthAmt(fetchedData.PO_HDR.Oth_amt);
      setGross_amt(fetchedData.PO_HDR.Gross_amt);
      setDisc_amt(fetchedData.PO_HDR.Disc_amt);
      setTaxable_amt(fetchedData.PO_HDR.Taxable_amt);
      setNet_amt(fetchedData.PO_HDR.Net_amt);
      const supplier = suppliers.find(s => s.Supp_cd === fetchedData.PO_HDR.Supp_id);
      if (supplier) {
        setSelectedSupplier(supplier.Supp_cd);
      }
      const selectedIndentss = fetchedData.PO_DTL.map((detail, index) => ({
        Sl_no: detail.Sl_no,
        itemCd: detail.itemCd,
        Location_Desc: detail.Location_Desc,
        UOM: detail.UOM,
        Hsn_code: detail.Hsn_code,
        GST_per: detail.GST_per,
        Indent_loc: detail.Indent_loc,
        Indent_no: detail.Indent_no,
        qty: detail.qty,
        PO_qty: detail.PO_qty,
        Item_rate: detail.Item_rate,
        Gross_amt: detail.Gross_amt,
        Disc_per: detail.Disc_per,
        Disc_amt: detail.Disc_amt,
        Taxable_amt: detail.Taxable_amt,
        Sgst_per: detail.Sgst_per,
        Sgst_amt: detail.Sgst_amt,
        Cgst_per: detail.Cgst_per,
        Cgst_amt: detail.Cgst_amt,
        Igst_per: detail.Igst_per,
        Igst_amt: detail.Igst_amt,
        Ugst_per: detail.Ugst_per,
        Ugst_amt: detail.Ugst_amt,
        Net_amt: detail.Net_amt
      }));
      setSelectedIndentss(selectedIndentss);
      setIsEditing(true);
    } catch (err) {
      console.error("Error fetching PO data", err);
    }
  };

  useEffect(() => {
    if (PO_no) {
      fetchPOData();
    }
  }, [PO_no]);

  const [visibleRows, setVisibleRows] = useState()

  useEffect(() => {
    const totalRows = selectedIndentss.length;
    const emptyRowsNeeded = totalRows < 4 ? 4 - totalRows : 0;
    setVisibleRows([...selectedIndentss, ...Array(emptyRowsNeeded).fill({})]);
  }, [selectedIndentss]);


  const handleEditInputChange = (e, indent_no, field, index) => {
    const value = e.target.value;
    console.log(`Updating ${field} for indent ${indent_no}: ${value}`);

    setVisibleRows(prevIndents => {
      return prevIndents.map((indent, i) => {
        if (indent.Indent_no === indent_no && i === index) {
          const updatedIndent = { ...indent, [field]: value };

          const grossAmt = calculateGrossAmt(updatedIndent.PO_qty, updatedIndent.Item_rate);
          const discAmt = calculateDiscAmt(grossAmt, updatedIndent.Disc_per);
          const taxableAmt = calculateTaxableAmt(grossAmt, discAmt);
          const { cgstPer, sgstPer, igstPer } = calculateTaxes(updatedIndent.GST_per, Des_state_cd, extractStateFromGST(supplier.Gst_No));
          const sgstAmt = (sgstPer * taxableAmt) / 100;
          const cgstAmt = (cgstPer * taxableAmt) / 100;
          const igstAmt = (igstPer * taxableAmt) / 100;

          updatedIndent.Gross_amt = grossAmt;
          updatedIndent.Disc_amt = discAmt;
          updatedIndent.Taxable_amt = taxableAmt;
          updatedIndent.Sgst_amt = sgstAmt;
          updatedIndent.Cgst_amt = cgstAmt;
          updatedIndent.Igst_amt = igstAmt;
          updatedIndent.Net_amt = taxableAmt + sgstAmt + cgstAmt + igstAmt;

          return updatedIndent;
        }
        return indent;
      });
    });

    updatePOHeader();
  };
  const updatePOHeader = () => {
    const grossAmt = visibleRows.reduce((sum, indent) => sum + (indent.Gross_amt || 0), 0);
    const discAmt = visibleRows.reduce((sum, indent) => sum + (indent.Disc_amt || 0), 0);
    const taxableAmt = visibleRows.reduce((sum, indent) => sum + (indent.Taxable_amt || 0), 0);
    const netAmt = visibleRows.reduce((sum, indent) => sum + (indent.Net_amt || 0), 0);

    const totalAmt = netAmt + parseFloat(Freight_amt || 0) + parseFloat(Insurance_amt || 0) + parseFloat(Oth_amt || 0);

    setGross_amt(grossAmt);
    setDisc_amt(discAmt);
    setTaxable_amt(taxableAmt);
    setNet_amt(netAmt);
    setTotal_Amt(totalAmt);
  };


  const handleRefresh = () => { setPO_no(''); setPoType('CAPX'); setDeliTerms(''); setDeliveryDate(''); setDesGst(''); setDesState(''); setPayTerms(''); setItemType('Import'); setCreditDays(''); setSelectedSupplier(''); setFreightAmt(''); setInsuranceAmt(''); setOthAmt(''); setTotal_Amt(''); setItems([]); setIndents([]); setSelectedIndents([]); };

  const handleApprove = (id) => {

  };

  const handlePrint = () => {

  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  const [formMode, setFormMode] = useState('');
  const userPermissions = user ? {
    add: user.add_permission,
    edit: user.edit_permission,
    view: user.view_permission,
    save: user.save_permission,
    approve: user.approve_permission,
    print: user.print_permission,
    close: user.close_permission,
    clear: user.clear_screen_permission
  } : {};

  const getButtonState = (permissionType) => {
    switch (formMode) {
      case 'create':
        return permissionType === 'create' || permissionType === 'clear' || permissionType === 'save' || permissionType === 'close';
      case 'edit':
        return permissionType === 'edit' || permissionType === 'clear' || permissionType === 'save' || permissionType === 'close';
      case 'view':
        return permissionType === 'view' || permissionType === 'clear' || permissionType === 'close';
      case 'approve':
        return permissionType === 'clear' || permissionType === 'view' || permissionType === 'approve' || permissionType === 'print' || permissionType === 'close';
      default:
        return false;
    }
  };

  return (
    <>
      <div style={{ margin: '0 auto', backgroundColor: '#dbc4a9', padding: '1rem', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', border: '1px solid black', borderRadius: '10px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <img src={A1} alt="item" style={{ width: "20%" }} />
          <h1 style={{ color: "darkblue", fontSize: "1.95rem", fontWeight: "1000", marginBottom: "1rem" }}>PURCHASE ORDER</h1>
          <div style={{ display: "flex", gap: "1rem", cursor: 'pointer' }}>
            <div style={{
              width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: userPermissions.add && userPermissions.add !== 0 ? "pointer" : "not-allowed",
              opacity: userPermissions.add && userPermissions.add !== 0 && getButtonState('create') ? 1 : 0.5
            }}>
              <FaPlus size={30} onClick={() => {
                if (userPermissions.add && userPermissions.add !== 0) {
                  toggleModal();
                  setShowEditFormm(false);
                  setShowCreateForm(true);
                  setFormMode('create');
                }
              }} />
            </div>
            <div style={{
              width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: userPermissions.edit && userPermissions.edit !== 0 ? "pointer" : "not-allowed",
              opacity: userPermissions.edit && userPermissions.edit !== 0 && getButtonState('edit') ? 1 : 0.5
            }}>
              <MdEdit size={30} onClick={() => {
                if (userPermissions.edit && userPermissions.edit !== 0) {
                  setShowEditFormm(true);
                  setShowCreateForm(false);
                  setFormMode('edit');
                  setIsEditing(true);
                } else {
                  alert("You do not have permission to edit.");
                }
              }} />
            </div>
            <div style={{
              width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: userPermissions.view && userPermissions.view !== 0 ? "pointer" : "not-allowed",
              opacity: userPermissions.view && userPermissions.view !== 0 && getButtonState('view') ? 1 : 0.5
            }}>
              <LuView size={30} onClick={() => {
                if (userPermissions.view && userPermissions.view !== 0) {
                  setShowEditFormm(true);
                  setShowCreateForm(false);
                  setFormMode('view');
                } else {
                  alert("You do not have permission to view.");
                }
              }} />
            </div>
            <div style={{
              width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: userPermissions.clear && userPermissions.clear !== 0 && getButtonState('clear') ? "pointer" : "not-allowed",
              opacity: userPermissions.clear && userPermissions.clear !== 0 && getButtonState('clear') ? 1 : 0.5
            }}>
              <HiOutlineRefresh size={30} onClick={userPermissions.clear && userPermissions.clear !== 0 ? handleRefresh : null} />
            </div>

            <div style={{
              width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: userPermissions.save && userPermissions.save !== 0 && getButtonState('save') ? "pointer" : "not-allowed",
              opacity: userPermissions.save && userPermissions.save !== 0 && getButtonState('save') ? 1 : 0.5
            }}>
              <FaSave size={30} onClick={userPermissions.save && userPermissions.save !== 0 ? handleSave : null} />
            </div>
            <div style={{
              width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: userPermissions.approve && userPermissions.approve !== 0 && getButtonState('approve') ? "pointer" : "not-allowed",
              opacity: userPermissions.approve && userPermissions.approve !== 0 && getButtonState('approve') ? 1 : 0.5
            }}>
              <FaCheck size={30} onClick={userPermissions.approve && userPermissions.approve !== 0 ? handleApprove : null} />
            </div>
            <div style={{
              width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: userPermissions.print && userPermissions.print !== 0 && getButtonState('print') ? "pointer" : "not-allowed",
              opacity: userPermissions.print && userPermissions.print !== 0 && getButtonState('print') ? 1 : 0.5
            }} title='Print'>
              <IoPrint size={30} onClick={userPermissions.print && userPermissions.print !== 0 ? handlePrint : null} />
            </div>
            <div style={{
              width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: userPermissions.close && userPermissions.close !== 0 && getButtonState('close') ? "pointer" : "not-allowed",
              opacity: userPermissions.close && userPermissions.close !== 0 && getButtonState('close') ? 1 : 0.5
            }} title='Exit'>
              <RiCloseLargeFill size={30} onClick={userPermissions.close && userPermissions.close !== 0 ? handleBack : null} />
            </div>
          </div>
        </div>
        <hr />

        {showCreateForm && (
          <>
            {isModalOpen && (
              <div style={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ backgroundColor: '#dbc4a9', padding: '16px', borderRadius: '8px', width: '80%' }}>
                  <h4 className='text-center'>PENDING INDENT</h4>
                  <div style={{ overflowY: 'auto', maxHeight: '200px' }}>
                    <table style={{ minWidth: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
                      <thead>
                        <tr>
                          <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Location</th>
                          <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Indent No</th>
                          <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Indent Dt</th>
                          <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item</th>
                          <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item desc</th>
                          <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>UOM</th>
                          <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Approved Qty</th>
                          <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Supplier</th>
                          <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Remarks</th>
                          <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Selection</th>
                        </tr>
                      </thead>
                      <tbody>
                        {combinedData.map((indent, index) => {
                          const key = `${indent.indent_no}-${index}`;
                          return (
                            <tr key={index}>
                              <td style={{ border: '1px solid black', padding: '8px' }}>{indent.location_id}</td>
                              <td style={{ border: '1px solid black', padding: '8px' }}>{indent.indent_no}</td>
                              <td style={{ border: '1px solid black', padding: '8px' }}>{new Date(indent.Indent_dt).toLocaleDateString()}</td>
                              <td style={{ border: '1px solid black', padding: '8px' }}>{indent.itemCd}</td>
                              <td style={{ border: '1px solid black', padding: '8px' }}>{indent.itemDesc}</td>
                              <td style={{ border: '1px solid black', padding: '8px' }}>{indent.itemUOM}</td>
                              <td style={{ border: '1px solid black', padding: '8px' }}>{indent.Approved_qty}</td>
                              <td style={{ border: '1px solid black', padding: '8px' }}>{indent.Supp_Nm}</td>
                              <td style={{ border: '1px solid black', padding: '8px' }}>{indent.Remarks}</td>
                              <td style={{ border: '1px solid black', padding: '8px' }}><input type="checkbox" checked={selectedIndents.includes(key)} onChange={() => handleIndentSelection(indent.indent_no, index)} />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div style={{ marginTop: '16px' }}>
                    <button style={{ backgroundColor: "blue", color: 'white', padding: '2px 20px', borderRadius: '4px', marginRight: '10px', cursor: 'pointer' }} onClick={handleIndentAdd}>Add</button>
                    <button style={{ backgroundColor: "blue", color: 'white', padding: '2px 20px', borderRadius: '4px', marginRight: '10px', cursor: 'pointer' }} onClick={toggleModal}>Cancel</button>
                  </div>
                </div>
              </div>
            )}

            {isDataVisible && selectedIndents.length > 0 && (
              <div style={{ marginTop: '32px' }}>
                <div className='p-1' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '10px', color: 'darkblue' }}>Purchase Order No:</label>
                      <input type="number" name="PO_no" style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '86px', color: 'darkblue' }}>PO Type:</label>
                      <select name="Po_Type" value={Po_Type} onChange={(e) => setPoType(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }}>
                        <option value="CAPX">CAPX</option>
                        <option value="OPEX">OPEX</option>
                      </select>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '42px', color: 'darkblue' }}>Delivery Terms:</label>
                      <input type="text" name="Deli_terms" value={Deli_terms} onChange={(e) => setDeliTerms(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} maxLength="20" />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '67px', color: 'darkblue' }}>Delivery Dt:</label>
                      <input type="date" name="Deli_dt" value={deliveryDate} onChange={(e) => setDeliveryDate(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '32px', color: 'darkblue' }}>Destination GST:</label>
                      <input type="text" name="Des_gst" value={Des_gst} onChange={handleDesGstChange} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} maxLength="12" />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '24px', color: 'darkblue' }}>Destination State:</label>
                      <input type="text" name="Des_state_cd" value={Des_state_cd} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                  </div>

                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '65px', color: 'darkblue' }}>P O Dt:</label>
                      <input type="date" name="PO_Dt" value={PO_Dt} onChange={(e) => setPoDate(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '42px', color: 'darkblue' }}>Item Type:</label>
                      <select name="Item_type" value={Item_type} onChange={(e) => setItemType(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }}>
                        <option value="Import">Import</option>
                        <option value="Local">Local</option>
                      </select>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '4px', color: 'darkblue' }}>Payment Terms:</label>
                      <input type="text" name="Pay_terms" value={Pay_terms} onChange={(e) => setPayTerms(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} maxLength="20" />
                    </div>
                  </div>

                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '44px', color: 'darkblue' }}>Supplier:</label>
                      <select onChange={handleSupplierChange} value={Supp_id} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} >
                        <option value="">Select</option>
                        {suppliers.map((supplier) => (<option key={supplier.Supp_cd} value={supplier.Supp_cd}>{supplier.Supp_Nm}</option>))}
                      </select>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '22px', color: 'darkblue' }}>Credit Days:</label>
                      <input type="number" name="Credit_days" value={Credit_days} onChange={handleCreditDaysChange} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '13px', color: 'darkblue' }}>Supplier GST:</label>
                      <input type="text" name="Supp_gst" value={supplier ? supplier.Gst_No : ''} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '6px', color: 'darkblue' }}>Supplier State:</label>
                      <input type="text" name="Supp_state_cd" value={supplier ? extractStateFromGST(supplier.Gst_No) : ''} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                    </div>
                  </div>
                </div>

                <div style={{ overflowY: 'auto', maxHeight: '190px' }}>
                  <table style={{ minWidth: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
                    <thead>
                      <tr>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Sl</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Code</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Description</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>UOM</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>HSN CODE</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>GST %</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Indent Location</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Indent No</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Indent Qty</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>PO Qty</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Rate</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Gross Amt</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Disc %</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Disc Amt</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Taxable amt</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>SGST %</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>SGST AMT</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>CGST %</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>CGST Amt</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>IGST %</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>IGST Amt</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>UGST %</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>UGST Amt</th>
                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Net Amt</th>
                      </tr>
                    </thead>
                    <tbody>
                      {combinedData
                        .filter((indent, index) => selectedIndents.includes(`${indent.indent_no}-${index}`))
                        .map((indent, index) => (
                          <tr key={index}>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{index + 1}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '2%' }}>{indent.itemCd}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '2%' }}>{indent.Location_Desc}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '2%' }}>{indent.itemUOM}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '2%' }}>{indent.hsnCode}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '2%' }}>{indent.taxRate}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '2%' }}>{indent.Location_Desc}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '2%' }}>{indent.indent_no}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '2%' }}>{indent.qty}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '10%' }}><input type="text" name='PO_qty' value={indent.PO_qty || ''} onChange={(e) => { if (!Supp_id) { alert('Please select a supplier before entering the PO quantity.'); return; } handleInputChange(e, indent.indent_no, 'PO_qty', index); }} onClick={() => { if (!Supp_id) { alert('Please select a supplier before entering the PO quantity.'); } }} style={{ width: '100%', border: 'black', outline: 'black', backgroundColor: 'transparent' }} maxLength={10} pattern="[A-Za-z0-9]+" /></td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '10%' }}><input type="text" name='Item_rate' value={indent.Item_rate || ''} onChange={(e) => handleInputChange(e, indent.indent_no, 'Item_rate', index)} style={{ width: '100%', border: 'black', outline: 'black', backgroundColor: 'transparent' }} maxLength={10} pattern="[A-Za-z0-9]+" /></td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '10%' }}>{isNaN(calculateGrossAmt(indent.PO_qty, indent.Item_rate)) ? '' : calculateGrossAmt(indent.PO_qty, indent.Item_rate).toFixed(2)}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '10%' }}><input type="text" name='Disc_per' onChange={(e) => handleInputChange(e, indent.indent_no, 'Disc_per', index)} value={indent.Disc_per || ''} style={{ width: '100%', border: 'black', outline: 'black', backgroundColor: 'transparent' }} maxLength={10} pattern="[A-Za-z0-9]+" /></td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '10%' }}>{isNaN(calculateDiscAmt(calculateGrossAmt(indent.PO_qty, indent.Item_rate), indent.Disc_per)) ? '' : calculateDiscAmt(calculateGrossAmt(indent.PO_qty, indent.Item_rate), indent.Disc_per).toFixed(2)}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '10%' }}>{isNaN(calculateTaxableAmt(calculateGrossAmt(indent.PO_qty, indent.Item_rate), calculateDiscAmt(calculateGrossAmt(indent.PO_qty, indent.Item_rate), indent.Disc_per))) ? '' : calculateTaxableAmt(calculateGrossAmt(indent.PO_qty, indent.Item_rate), calculateDiscAmt(calculateGrossAmt(indent.PO_qty, indent.Item_rate), indent.Disc_per)).toFixed(2)}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '10%' }}>{indent.sgstPer}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '10%' }}>{indent.sgstAmt ? indent.sgstAmt.toFixed(2) : ''}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '10%' }}>{indent.cgstPer ? indent.cgstPer.toFixed(2) : ''}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '10%' }}>{indent.cgstAmt ? indent.cgstAmt.toFixed(2) : ''}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '10%' }}>{indent.igstPer ? indent.igstPer.toFixed(2) : ''}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '10%' }}>{indent.igstAmt ? indent.igstAmt.toFixed(2) : ''}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.ugstper ? indent.ugstper.toFixed(2) : ''}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.ugstAmt ? indent.ugstAmt.toFixed(2) : ''}</td>
                            <td style={{ border: '1px solid black', padding: '8px', textAlign: "end", width: '10%' }}>{(calculateTaxableAmt(calculateGrossAmt(indent.PO_qty, indent.Item_rate), calculateDiscAmt(calculateGrossAmt(indent.PO_qty, indent.Item_rate), indent.Disc_per)) + indent.sgstAmt + indent.cgstAmt + indent.igstAmt).toFixed(2)}</td>
                          </tr>
                        ))}
                    </tbody>

                  </table>
                </div>
                <p className='mb-0' style={{ textAlign: 'right', marginRight: '10px' }}><strong>Total:</strong>
                  {combinedData
                    .filter((indent, index) => selectedIndents.includes(`${indent.indent_no}-${index}`))
                    .reduce((total, indent) => {
                      const taxableAmt = calculateTaxableAmt(calculateGrossAmt(indent.PO_qty, indent.Item_rate), calculateDiscAmt(calculateGrossAmt(indent.PO_qty, indent.Item_rate), indent.Disc_per));
                      return total + taxableAmt + indent.sgstAmt + indent.cgstAmt + indent.igstAmt;
                    }, 0).toFixed(2)}
                </p>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '6px', color: 'darkblue' }}>Freight amt:</label>
                    <input type="text" name="Freight_amt" value={Freight_amt} onChange={(e) => { setFreightAmt(e.target.value); calculateTotalAmountAndDetails(); }} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', width: '50%', outline: 'none', backgroundColor: 'transparent' }} maxLength={10} pattern="[A-Za-z0-9]+" />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '6px', color: 'darkblue' }}>Insurace amt:</label>
                    <input type="text" name="Insurance_amt" value={Insurance_amt} onChange={(e) => { setInsuranceAmt(e.target.value); calculateTotalAmountAndDetails(); }} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', width: '50%', outline: 'none', backgroundColor: 'transparent' }} maxLength={10} pattern="[A-Za-z0-9]+" />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '6px', color: 'darkblue' }}>Other amt:</label>
                    <input type="text" name="Oth_amt" value={Oth_amt} onChange={(e) => { setOthAmt(e.target.value); calculateTotalAmountAndDetails(); }} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', width: '50%', outline: 'none', backgroundColor: 'transparent' }} maxLength={10} pattern="[A-Za-z0-9]+" />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '6px', color: 'darkblue' }}>Total amt:</label>
                    <input type="text" name="Total_amt" value={Total_amt || 0} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', width: '50%', outline: 'none', backgroundColor: 'transparent' }} />
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {showEditformm && (
          <div style={{ marginTop: '22px' }}>
            <div className='p-1' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '86px', color: 'darkblue' }}>P O No Edit:</label>
                  <input type="text" placeholder="Search by P O No"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ width: '60%', padding: '4px', marginRight: '10px', backgroundColor: '#dbc4a9', border: '1px solid #ccc', }} />

                  <select name="PO_no" value={PO_no} onChange={handlePOSelectChangee} style={{ width: '20%', padding: '4px', backgroundColor: '#dbc4a9', border: '1px solid #ccc', }} >
                    <option value=""></option>
                    {poNumbers.map((poNumber, index) => (
                      <option key={index} value={poNumber}>{poNumber}</option>))}
                  </select>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '86px', color: 'darkblue' }}>PO Type:</label>
                  <select name="Po_Type" value={Po_Type} onChange={(e) => setPoType(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }}>
                    <option value="CAPX">CAPX</option>
                    <option value="OPEX">OPEX</option>
                  </select>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '42px', color: 'darkblue' }}>Delivery Terms:</label>
                  <input type="text" name="Deli_terms" value={Deli_terms} maxLength={20} pattern="[A-Za-z0-9]+" onChange={(e) => setDeliTerms(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '67px', color: 'darkblue' }}>Delivery Dt:</label>
                  <input type="date" name="Deli_dt" value={deliveryDate} onChange={(e) => setDeliveryDate(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '32px', color: 'darkblue' }}>Destination GST:</label>
                  <input type="text" name="Des_gst" value={Des_gst} maxLength={12} pattern="[A-Za-z0-9]+" onChange={handleDesGstChange} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '24px', color: 'darkblue' }}>Destination State:</label>
                  <input type="text" name="Des_state_cd" value={Des_state_cd} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} />
                </div>
              </div>

              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '65px', color: 'darkblue' }}>P O Dt:</label>
                  <input type="date" name="PO_Dt" value={PO_Dt || ''} onChange={(e) => setPoDate(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '42px', color: 'darkblue' }}>Item Type:</label>
                  <select name="Item_type" value={Item_type} onChange={(e) => setItemType(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }}>
                    <option value="Import">Import</option>
                    <option value="Local">Local</option>
                  </select>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '4px', color: 'darkblue' }}>Payment Terms:</label>
                  <input type="text" name="Pay_terms" value={Pay_terms} maxLength={20} pattern="[A-Za-z0-9]+" onChange={(e) => setPayTerms(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} />
                </div>
              </div>

              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '44px', color: 'darkblue' }}>Supplier:</label>
                  <select onChange={handleSupplierChange} value={Supp_id} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} >
                    <option value="">Select</option>
                    {suppliers.map((supplier) => (<option key={supplier.Supp_cd} value={supplier.Supp_cd}>{supplier.Supp_Nm}</option>))}
                  </select>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '22px', color: 'darkblue' }}>Credit Days:</label>
                  <input type="number" name="Credit_days" value={Credit_days} onChange={handleCreditDaysChange} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '13px', color: 'darkblue' }}>Supplier GST:</label>
                  <input type="text" name="Supp_gst" value={supplier ? supplier.Gst_No : ''} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '6px', color: 'darkblue' }}>Supplier State:</label>
                  <input type="text" name="Supp_state_cd" value={supplier ? extractStateFromGST(supplier.Gst_No) : ''} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '2px', width: '50%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} />
                </div>
              </div>
            </div>

            <div style={{ overflowY: 'auto', maxHeight: '190px' }}>
              <table style={{ minWidth: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Sl</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Code</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Description</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>UOM</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>HSN CODE</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>GST %</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Indent Location</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Indent No</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Indent Qty</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>PO Qty</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Rate</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Gross Amt</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Disc %</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Disc Amt</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Taxable amt</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>SGST %</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>SGST AMT</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>CGST %</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>CGST Amt</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>IGST %</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>IGST Amt</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>UGST %</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>UGST Amt</th>
                    <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Net Amt</th>
                  </tr>
                </thead>
                <tbody>
                  {visibleRows.map((indent, index) => (
                    <tr key={index}>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{index + 1}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.itemCd}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.Location_Desc}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.UOM}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.Hsn_code}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.GST_per}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.Indent_loc}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.Indent_no}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.qty}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}><input type="text" value={indent.PO_qty || ''} onChange={(e) => handleEditInputChange(e, indent.Indent_no, 'PO_qty', index)} style={{ width: '100%', border: 'black', outline: 'black', backgroundColor: 'transparent', }} disabled={formMode === 'view'} maxLength={10} pattern="[A-Za-z0-9]+" /></td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}><input type="text" value={indent.Item_rate || ''} onChange={(e) => handleEditInputChange(e, indent.Indent_no, 'Item_rate', index)} style={{ width: '100%', border: 'black', outline: 'black', backgroundColor: 'transparent', }} disabled={formMode === 'view'} maxLength={10} pattern="[A-Za-z0-9]+" /></td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{isNaN(calculateGrossAmt(indent.PO_qty, indent.Item_rate)) ? '' : calculateGrossAmt(indent.PO_qty, indent.Item_rate)}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}><input type="text" value={indent.Disc_per || ''} onChange={(e) => handleEditInputChange(e, indent.Indent_no, 'Disc_per', index)} style={{ width: '100%', border: 'black', outline: 'black', backgroundColor: 'transparent', }} disabled={formMode === 'view'} maxLength={10} pattern="[A-Za-z0-9]+" /></td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.Disc_amt}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{isNaN(calculateTaxableAmt(calculateGrossAmt(indent.PO_qty, indent.Item_rate), calculateDiscAmt(calculateGrossAmt(indent.PO_qty, indent.Item_rate), indent.Disc_per))) ? '' : calculateTaxableAmt(calculateGrossAmt(indent.PO_qty, indent.Item_rate), calculateDiscAmt(calculateGrossAmt(indent.PO_qty, indent.Item_rate), indent.Disc_per))}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.Sgst_per}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.Sgst_amt}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.Cgst_per}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.Cgst_amt}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.Igst_per}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.Igst_amt}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.Ugst_per}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.Ugst_amt}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: "end" }}>{indent.Net_amt}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <p className='mb-0' style={{ textAlign: 'right', marginRight: '10px' }}><strong>Total:</strong>{netAmount.toFixed(2)}</p>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '6px', color: 'darkblue' }}>Freight amt:</label>
                <input type="text" name="Freight_amt" value={Freight_amt} onChange={(e) => setFreightAmt(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', width: '50%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} maxLength={10} pattern="[A-Za-z0-9]+" />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '6px', color: 'darkblue' }}>Insurace amt:</label>
                <input type="text" name="Insurance_amt" value={Insurance_amt} onChange={(e) => setInsuranceAmt(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', width: '50%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} maxLength={10} pattern="[A-Za-z0-9]+" />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '6px', color: 'darkblue' }}>Other amt:</label>
                <input type="text" name="Oth_amt" value={Oth_amt} onChange={(e) => setOthAmt(e.target.value)} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', width: '50%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} maxLength={10} pattern="[A-Za-z0-9]+" />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}><label style={{ marginRight: '6px', color: 'darkblue' }}>Total amt:</label>
                <input type="text" name="Total_amt" value={Total_amt} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', width: '50%', outline: 'none', backgroundColor: 'transparent' }} disabled={formMode === 'view'} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PurchaseOrder;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const PurchaseOrderForm = () => {
//   const [PO_no, setPO_no] = useState('');
//   const [selectedIndentss, setSelectedIndentss] = useState([]);
//   const [POHeader, setPOHeader] = useState({
//     PO_Dt: null,
//     Po_Type: null,
//     Item_type: null,
//     Deli_terms: null,
//     Deli_dt: null,
//     Pay_terms: null,
//     Credit_days: null,
//     Des_gst: null,
//     Des_state_cd: null,
//     Supp_id: null,
//     Supp_gst: null,
//     Supp_state_cd: null,
//     Gross_amt: 0,
//     Disc_amt: 0,
//     Taxable_amt: 0,
//     Net_amt: 0,
//     Freight_amt: 0,
//     Insurance_amt: 0,
//     Oth_amt: 0,
//     Total_amt: 0,
//   });
//   const [formMode, setFormMode] = useState('edit'); // 'view' or 'edit' mode
//   const [isEditing, setIsEditing] = useState(false);

//   // Functions to handle input changes for the form data
//   const handlePOHeaderChange = (event, field) => {
//     const { value } = event.target;
//     setPOHeader(prevHeader => ({
//       ...prevHeader,
//       [field]: value
//     }));
//   };

//   const handleInputChange = (event, indentNo, field, index) => {
//     const { value } = event.target;
//     setSelectedIndentss(prevIndents => {
//       const updatedIndents = [...prevIndents];
//       updatedIndents[index] = {
//         ...updatedIndents[index],
//         [field]: value
//       };
//       return updatedIndents;
//     });
//   };

//   // Fetch PO data from API
//   const fetchPOData = async (PO_no) => {
//     try {
//       const response = await axios.get(`https://erpapi.emedha.in/pages/api/PURCHASE_ORDER/${PO_no}`);
//       const fetchedData = response.data;
//       setPOHeader(fetchedData.PO_HDR);  // Set PO Header data
//       setSelectedIndentss(fetchedData.PO_DTL);  // Set PO Detail data
//       setIsEditing(true);  // Set editing mode on
//     } catch (err) {
//       console.error("Error fetching PO data", err);
//     }
//   };

//   useEffect(() => {
//     if (PO_no) {
//       fetchPOData(PO_no);
//     }
//   }, [PO_no]);

//   // Handle Search for PO (Search Term input change)
//   const handleSearchChange = (event) => {
//     const searchTerm = event.target.value;
//     setPO_no(searchTerm);
//     if (searchTerm) {
//       fetchPOData(searchTerm);
//     }
//   };

//   // Handle Save operation (Post/Put)
//   const handleSave = async () => {
//     const grossAmt = selectedIndentss.reduce((sum, indent) => {
//       const qty = parseFloat(indent.PO_qty);
//       const rate = parseFloat(indent.Item_rate);
//       return sum + (qty * rate);
//     }, 0);

//     const discAmt = selectedIndentss.reduce((sum, indent) => {
//       const grossAmt = (parseFloat(indent.PO_qty) * parseFloat(indent.Item_rate));
//       return sum + (grossAmt * (parseFloat(indent.Disc_per) / 100));
//     }, 0);

//     const taxableAmt = selectedIndentss.reduce((sum, indent) => {
//       const grossAmt = (parseFloat(indent.PO_qty) * parseFloat(indent.Item_rate));
//       const discAmt = (grossAmt * (parseFloat(indent.Disc_per) / 100));
//       return sum + (grossAmt - discAmt);
//     }, 0);

//     const Net_amt = taxableAmt; // Assuming no additional deductions for simplicity
//     const Total_amt = Net_amt + parseFloat(POHeader.Freight_amt || 0) + parseFloat(POHeader.Insurance_amt || 0) + parseFloat(POHeader.Oth_amt || 0);
//     setPOHeader(prevHeader => ({ ...prevHeader, Total_amt }));

//     const poHeaderData = {
//       ...POHeader,
//       Gross_amt: grossAmt,
//       Disc_amt: discAmt,
//       Taxable_amt: taxableAmt,
//       Net_amt: Net_amt,
//       Total_amt: Total_amt
//     };

//     const poDtlData = selectedIndentss.map(indent => {
//       const grossAmt = parseFloat(indent.PO_qty) * parseFloat(indent.Item_rate);
//       const discAmt = grossAmt * (parseFloat(indent.Disc_per) / 100);
//       const taxableAmt = grossAmt - discAmt;
//       return { ...indent, Gross_amt: grossAmt, Disc_amt: discAmt, Taxable_amt: taxableAmt };
//     });

//     const requestData = { PO_HDR: poHeaderData, PO_DTL: poDtlData };

//     try {
//       if (isEditing) {
//         await axios.put(`https://erpapi.emedha.in/pages/api/API/PURCHASE_ORDER/${PO_no}`, requestData);
//         alert('Purchase Order updated successfully!');
//       } else {
//         await axios.post('https://erpapi.emedha.in/pages/api/PURCHASE_ORDER', requestData);
//         alert('Purchase Order added successfully!');
//       }
//     } catch (error) {
//       alert('Failed to save purchase order. Please try again.');
//     }
//   };

//   return (
//     <div>
//       <input type="text" value={PO_no} onChange={handleSearchChange} placeholder="Search Purchase Order" />
//       <button onClick={handleSave}>{isEditing ? 'Update Purchase Order' : 'Add Purchase Order'}</button>

//       {/* PO Header Section */}
//       <div>
//         <h3>Purchase Order Header</h3>
//         <div>
//           <label>PO Date: </label>
//           <input type="text" value={POHeader.PO_Dt || ''} onChange={(e) => handlePOHeaderChange(e, 'PO_Dt')} disabled={formMode === 'view'} />
//         </div>
//         <div>
//           <label>PO Type: </label>
//           <input type="text" value={POHeader.Po_Type || ''} onChange={(e) => handlePOHeaderChange(e, 'Po_Type')} disabled={formMode === 'view'} /> </div>
//         <div>
//           <label>Item Type: </label>
//           <input type="text" value={POHeader.Item_type || ''} onChange={(e) => handlePOHeaderChange(e, 'Item_type')} disabled={formMode === 'view'} />
//         </div>
//         <div>
//           <label>Delivery Terms: </label>
//           <input type="text" value={POHeader.Deli_terms || ''} onChange={(e) => handlePOHeaderChange(e, 'Deli_terms')} disabled={formMode === 'view'} />       </div>
//         <div>
//           <label>Payment Terms: </label>
//           <input type="text" value={POHeader.Pay_terms || ''} onChange={(e) => handlePOHeaderChange(e, 'Pay_terms')} disabled={formMode === 'view'} />       </div>
//         <div>
//           <label>Credit Days: </label>
//           <input type="text" value={POHeader.Credit_days || ''} onChange={(e) => handlePOHeaderChange(e, 'Credit_days')} disabled={formMode === 'view'} />      </div>
//         <div>
//           <label>Supplier ID: </label>
//           <input type="text" value={POHeader.Supp_id || ''} onChange={(e) => handlePOHeaderChange(e, 'Supp_id')} disabled={formMode === 'view'} /> </div>
//         <div>
//           <label>Supplier GST: </label>
//           <input type="text" value={POHeader.Supp_gst || ''} onChange={(e) => handlePOHeaderChange(e, 'Supp_gst')} disabled={formMode === 'view'} />  </div>
//         <div>
//           <label>Total Amount: </label>
//           <input type="text" value={POHeader.Total_amt || ''} disabled /></div>
//       </div>

//       {/* PO Detail Section */}
//       <h3>Purchase Order Details</h3>
//       <table>
//         <thead>
//           <tr>
//             <th>Sl No</th>
//             <th>Item Code</th>
//             <th>UOM</th>
//             <th>HSN Code</th>
//             <th>GST %</th>
//             <th>Indent No</th>
//             <th>PO Qty</th>
//             <th>Item Rate</th>
//             <th>Gross Amt</th>
//             <th>Disc %</th>
//             <th>Disc Amt</th>
//             <th>Taxable Amt</th>
//           </tr>
//         </thead>
//         <tbody>
//           {selectedIndentss.map((indent, index) => (
//             <tr key={index}>
//               <td>{index + 1}</td>
//               <td>{indent.itemCd}</td>
//               <td>{indent.UOM}</td>
//               <td>{indent.Hsn_code}</td>
//               <td>{indent.GST_per}</td>
//               <td>{indent.Indent_no}</td>
//               <td><input type="number" value={indent.PO_qty || ''} onChange={(e) => handleInputChange(e, indent.Indent_no, 'PO_qty', index)} disabled={formMode === 'view'} /></td>
//               <td><input type="number" value={indent.Item_rate || ''} onChange={(e) => handleInputChange(e, indent.Indent_no, 'Item_rate', index)} disabled={formMode === 'view'} /></td>
//               <td>{(parseFloat(indent.PO_qty) * parseFloat(indent.Item_rate)).toFixed(2)}</td>
//               <td><input type="number" value={indent.Disc_per || ''} onChange={(e) => handleInputChange(e, indent.Indent_no, 'Disc_per', index)} disabled={formMode === 'view'} /></td>
//               <td>{((parseFloat(indent.PO_qty) * parseFloat(indent.Item_rate)) * (parseFloat(indent.Disc_per) / 100)).toFixed(2)}</td>
//               <td>{((parseFloat(indent.PO_qty) * parseFloat(indent.Item_rate)) - ((parseFloat(indent.PO_qty) * parseFloat(indent.Item_rate)) * (parseFloat(indent.Disc_per) / 100))).toFixed(2)}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <div>
//         <p><strong>Total Amount:</strong> {POHeader.Total_amt.toFixed(2)}</p>
//         <div>
//           <label>Freight amt:</label>
//           <input type="number" value={POHeader.Freight_amt} onChange={(e) => handlePOHeaderChange(e, 'Freight_amt')} disabled={formMode === 'view'} />
//         </div>
//         <div>
//           <label>Insurance amt:</label>
//           <input type="number" value={POHeader.Insurance_amt} onChange={(e) => handlePOHeaderChange(e, 'Insurance_amt')} disabled={formMode === 'view'} />
//         </div>
//         <div>
//           <label>Other amt:</label>
//           <input type="number" value={POHeader.Oth_amt} onChange={(e) => handlePOHeaderChange(e, 'Oth_amt')} disabled={formMode === 'view'} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PurchaseOrderForm;

