import React, { useEffect, useState } from "react";
import { FaCheck, FaPlus } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { LuView } from "react-icons/lu";
import { HiOutlineRefresh } from "react-icons/hi";
import { FaSave } from "react-icons/fa";
import { IoPrint } from "react-icons/io5";
import { RiCloseLargeFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import A1 from '../Images/logo-w.png';
import axios from 'axios';

const Suppliermst = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        Supp_Nm: '', Supp_Address: '', Contact_Person: '', Contact_Person_Ph: '', Contact_Person_Email: '',
        Credit_Days: '', TIN: '', PAN: '', TDS_Section: '', Bank_Acc_No: '',
        Bank_Branch: '', IFSC: '', Gst_Reg: '', Gst_No: ''
    });
    const [showForm, setShowForm] = useState(false);
    const [items, setItems] = useState([]);
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userName = user ? user.User_nm : 'Guest';
    const user_Id = user ? user.id : null;
    useEffect(() => {
        fetchItems();
    }, [user_Id]);

    const fetchItems = async () => {
        try {
            const response = await fetch('https://erpapi.emedha.in/pages/api/api/suppliers');
            if (!response.ok) {
                throw new Error('Failed to fetch items');
            }
            const data = await response.json();
            setItems(data);
        } catch (err) {
            console.error('Error fetching items:', err.message);
        }
    };

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showSearchh, setShowSearchh] = useState(false);
    const fetchItemss = async (searchTerm = '') => {
        try {
            const response = await fetch(`https://erpapi.emedha.in/pages/api/api/suppliers?searchTerm=${searchTerm}`);
            if (!response.ok) {
                throw new Error('Failed to fetch items');
            }
            const data = await response.json();
            setFilteredItems(data);
        } catch (err) {
            console.error('Error fetching items:', err.message);
        }
    };

    useEffect(() => {
        if (searchTerm) {
            fetchItemss(searchTerm);
        } else {
            setFilteredItems([]);
        }
    }, [searchTerm]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);



    const handleSaveItem = async () => {

        if (!formData.Supp_Nm || !formData.Bank_Acc_No || !formData.IFSC) {
            alert('Supp_Nm, Bank_Acc_No, and IFSC are required!');
            return;
        }
        const payload = {
            Supp_Nm: formData.Supp_Nm,
            Supp_Address: formData.Supp_Address,
            Contact_Person: formData.Contact_Person,
            Contact_Person_Ph: formData.Contact_Person_Ph,
            Contact_Person_Email: formData.Contact_Person_Email,
            Credit_Days: formData.Credit_Days,
            TIN: formData.TIN,
            PAN: formData.PAN,
            TDS_Section: formData.TDS_Section,
            Bank_Acc_No: formData.Bank_Acc_No,
            Bank_Branch: formData.Bank_Branch,
            IFSC: formData.IFSC,
            Gst_Reg: formData.Gst_Reg,
            Gst_No: formData.Gst_No,
        };
        try {
            if (isEditing) {
                await axios.put(`https://erpapi.emedha.in/pages/api/api/suppliers/${editId}`, payload, {
                    headers: { 'Content-Type': 'application/json' }
                });
                setItems(items.map(item => (item.Supp_cd === editId ? { ...formData, id: editId } : item)));
                alert('Supplier updated successfully!');
            } else {
                const response = await axios.post('https://erpapi.emedha.in/pages/api/api/suppliers', payload, {
                    headers: { 'Content-Type': 'application/json' }
                });
                setItems([...items, { ...formData, id: response.data.id }]);
                alert('Supplier added successfully!');
            }
            setShowForm(false);
        } catch (error) {
            console.error('Error saving supplier:', error);
        }
    };
    const handleEditItem = (item) => {
        setSearchTerm(item.Supp_cd);
        setFormData({
            Supp_Nm: item.Supp_Nm,
            Supp_Address: item.Supp_Address,
            Contact_Person: item.Contact_Person,
            Contact_Person_Ph: item.Contact_Person_Ph,
            Contact_Person_Email: item.Contact_Person_Email,
            Credit_Days: item.Credit_Days,
            TIN: item.TIN,
            PAN: item.PAN,
            TDS_Section: item.TDS_Section,
            Bank_Acc_No: item.Bank_Acc_No,
            Bank_Branch: item.Bank_Branch,
            IFSC: item.IFSC,
            Gst_Reg: item.Gst_Reg,
            Gst_No: item.Gst_No,
        });
        setEditId(item.Supp_cd);
        setIsEditing(true);
        setShowForm(true);
        setDropdownVisible1(false);
        setShowSearchh(false);
    };
    const [showSearch, setShowSearch] = useState(false);
    const [dropdownVisible1, setDropdownVisible1] = useState(false);
    const handleBack = () => { navigate(-1); };
    const toggleForm = () => {
        setShowForm(prevState => !prevState);
        setFormData({ Supp_Nm: '', Supp_Address: '', Contact_Person: '', Contact_Person_Ph: '', Contact_Person_Email: '', Credit_Days: '', TIN: '', PAN: '', TDS_Section: '', Bank_Acc_No: '', Bank_Branch: '', IFSC: '', Gst_Reg: '', Gst_No: '' });
        setIsEditing(false);
        setEditId(null);
        setShowForm(true);
        setShowSearch(false);
        setShowSearchh(true);
    };

    const latestItem = items.reduce((max, item) => (item.Supp_cd > max.Supp_cd ? item : max), { Supp_cd: 0 });

    const handleRefresh = () => {
        setSelectedItem({ Supp_Nm: '', Supp_Address: '', Contact_Person: '', Contact_Person_Ph: '', Contact_Person_Email: '', Credit_Days: '', TIN: '', PAN: '', TDS_Section: '', Bank_Acc_No: '', Bank_Branch: '', IFSC: '', Gst_Reg: '', Gst_No: '' });
        setFormData({ Supp_Nm: '', Supp_Address: '', Contact_Person: '', Contact_Person_Ph: '', Contact_Person_Email: '', Credit_Days: '', TIN: '', PAN: '', TDS_Section: '', Bank_Acc_No: '', Bank_Branch: '', IFSC: '', Gst_Reg: '', Gst_No: '' });
    };
    const handleViewItem = (item) => {
        setSelectedItem(item);
        setShowForm(true);
        setFormMode('view');
        setShowSearchh(false);
        setShowSearch(true)
    };
    const handleInputBankChangee = (e) => {
        let value = e.target.value;
        if (/^\d{0,11}$/.test(value)) {
            setFormData({
                ...formData,
                Bank_Acc_No: value
            });
        }
    };
    const handleInputTinChangee = (e) => {
        let value = e.target.value;
        if (/^\d{0,11}$/.test(value)) {
            setFormData({
                ...formData,
                TIN: value
            });
        }
    };
    const handleInputCreditChangee = (e) => {
        let value = e.target.value;
        if (/^\d{0,3}$/.test(value)) {
            setFormData({
                ...formData,
                Credit_Days: value
            });
        }
    };
    const handleInputCPChangee = (e) => {
        let value = e.target.value;
        if (/^\d{0,11}$/.test(value)) {
            setFormData({
                ...formData,
                Contact_Person_Ph: value
            });
        }
    };
    const handleInputTdsChangge = (e) => {
        let value = e.target.value;
        if (/^[a-zA-Z0-9]{0,10}$/.test(value)) {
            setFormData({
                ...formData,
                TDS_Section: value
            });
        }
    };
    const handleIfscPanInputChange = (e) => {
        const { value } = e.target;
        if (/^[a-zA-Z0-9]*$/.test(value) && value.length <= 10) {
            setFormData({
                ...formData,
                PAN: value
            });
        }
    };
    const handleIfscInputChange = (e) => {
        const { value } = e.target;
        if (/^[a-zA-Z0-9]*$/.test(value) && value.length <= 10) {
            setFormData({
                ...formData,
                IFSC: value
            });
        }
    };
    const handleBankBranchInputChange = (e) => {
        const { value } = e.target;
        if (/^[a-zA-Z\s]*$/.test(value) && value.length <= 30) {
            setFormData({
                ...formData,
                Bank_Branch: value
            });
        }
    };



    const [formMode, setFormMode] = useState('');
    const userPermissions = user ? {
        add: user.add_permission,
        edit: user.edit_permission,
        view: user.view_permission,
        save: user.save_permission,
        approve: user.approve_permission,
        print: user.print_permission,
        close: user.close_permission,
        clear: user.clear_screen_permission
    } : {};


    const getButtonState = (permissionType) => {
        switch (formMode) {
            case 'create':
                return permissionType === 'create' || permissionType === 'clear' || permissionType === 'save' || permissionType === 'close';
            case 'edit':
                return permissionType === 'edit' || permissionType === 'clear' || permissionType === 'save' || permissionType === 'close';
            case 'view':
                return permissionType === 'view' || permissionType === 'clear' || permissionType === 'close';
            case 'approve':
                return permissionType === 'clear' || permissionType === 'view' || permissionType === 'approve' || permissionType === 'print' || permissionType === 'close';
            default:
                return false;
        }
    };

    const handleApprove = (id) => {
        if (id) {
            axios
                .put(`https://erpapi.emedha.in/pages/api/api/supplier-mst/approve/${id}`)
                .then((response) => {
                    console.log('Item approved successfully:', response.data);
                    setItems((prevItems) =>
                        prevItems.map((item) =>
                            item.Supp_cd === id ? { ...item, isApproved: true } : item
                        )
                    );
                    alert('Item approved successfully');
                })
                .catch((error) => {
                    console.error('Error approving item:', error);
                    alert('Failed to approve item');
                });
        } else {
            alert("Item ID is invalid.");
        }
    };

    const handlePrint = () => {
        const printContent = document.getElementById("print-content");
        if (printContent) {
            const originalContent = document.body.innerHTML;
            document.body.innerHTML = printContent.innerHTML;
            window.print();
            document.body.innerHTML = originalContent;
        } else {
            alert("Print content not found!");
        }
    };

    return (
        <>
            <div style={{ backgroundColor: "#dbc4a9", padding: "1rem", margin: "0 auto", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", border: '1px solid black', borderRadius: '10px' }} >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img src={A1} alt="item" style={{ width: "20%" }} />
                    <h1 style={{ color: "darkblue", fontSize: "1.95rem", fontWeight: "1000", marginBottom: "1rem" }}>
                        SUPPLIER MASTER
                    </h1>
                    <div style={{ display: "flex", gap: "1rem", cursor: 'pointer' }}>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.add && userPermissions.add !== 0 ? "pointer" : "not-allowed",
                            opacity: userPermissions.add && userPermissions.add !== 0 && getButtonState('create') ? 1 : 0.5
                        }}>
                            <FaPlus size={30} onClick={() => {
                                if (userPermissions.add && userPermissions.add !== 0) {
                                    toggleForm();
                                    setShowSearch(false);
                                    setFormMode('create');
                                }
                            }} />
                        </div>

                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.edit && userPermissions.edit !== 0 ? "pointer" : "not-allowed",
                            opacity: userPermissions.edit && userPermissions.edit !== 0 && getButtonState('edit') ? 1 : 0.5
                        }}>
                            <MdEdit size={30} onClick={() => {
                                if (userPermissions.edit && userPermissions.edit !== 0) {
                                    setShowForm(true);
                                    setFormMode('edit');
                                    setShowSearch(true);
                                    setShowSearchh(false);
                                }
                            }} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.view && userPermissions.view !== 0 ? "pointer" : "not-allowed",
                            opacity: userPermissions.view && userPermissions.view !== 0 && getButtonState('view') ? 1 : 0.5
                        }}>
                            <LuView size={30} onClick={userPermissions.view && userPermissions.view !== 0 ? handleViewItem : null} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.clear && userPermissions.clear !== 0 && getButtonState('clear') ? "pointer" : "not-allowed",
                            opacity: userPermissions.clear && userPermissions.clear !== 0 && getButtonState('clear') ? 1 : 0.5
                        }}>
                            <HiOutlineRefresh size={30} onClick={userPermissions.clear && userPermissions.clear !== 0 ? handleRefresh : null} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.save && userPermissions.save !== 0 && getButtonState('save') ? "pointer" : "not-allowed",
                            opacity: userPermissions.save && userPermissions.save !== 0 && getButtonState('save') ? 1 : 0.5
                        }}>
                            <FaSave size={30} onClick={userPermissions.save && userPermissions.save !== 0 ? handleSaveItem : null} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.approve && userPermissions.approve !== 0 && getButtonState('approve') ? "pointer" : "not-allowed",
                            opacity: userPermissions.approve && userPermissions.approve !== 0 && getButtonState('approve') ? 1 : 0.5
                        }}>
                            <FaCheck size={30} onClick={userPermissions.approve && userPermissions.approve !== 0 ? () => handleApprove(selectedItem.Supp_cd) : null} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.print && userPermissions.print !== 0 && getButtonState('print') ? "pointer" : "not-allowed",
                            opacity: userPermissions.print && userPermissions.print !== 0 && getButtonState('print') ? 1 : 0.5
                        }}>
                            <IoPrint size={30} onClick={userPermissions.print && userPermissions.print !== 0 ? handlePrint : null} />
                        </div>
                        <div style={{
                            width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            cursor: userPermissions.close && userPermissions.close !== 0 && getButtonState('close') ? "pointer" : "not-allowed",
                            opacity: userPermissions.close && userPermissions.close !== 0 && getButtonState('close') ? 1 : 0.5
                        }}>
                            <RiCloseLargeFill size={30} onClick={userPermissions.close && userPermissions.close !== 0 ? handleBack : null} />
                        </div>
                    </div>
                </div>
                <p>Welcome To Supplier Master: {userName}</p>
                <hr />
                {showForm && (
                    <>
                        <div className="container">
                            {showSearch && (
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '32%', position: 'relative' }}>

                                    <input type="text" placeholder="Search by Supp CD" value={searchTerm}
                                        onChange={(e) => { setSearchTerm(e.target.value); setDropdownVisible1(true); }}
                                        style={{ marginBottom: "1rem", padding: "0.25rem", border: "1px solid #dbc4a9", width: '100%' }} />
                                    {filteredItems.length > 0 && dropdownVisible1 && (
                                        <ul style={{ maxHeight: "150px", overflowY: "auto", border: "1px solid #dbc4a9", width: "100%", position: "absolute", backgroundColor: "#fff", zIndex: 1, marginTop: '5px', padding: 0, top: '100%' }}>
                                            {filteredItems.map((item) => (
                                                <li
                                                    key={item.id}
                                                    onClick={() => handleEditItem(item)}
                                                    style={{ padding: "0.5rem", cursor: "pointer", listStyleType: "none", borderBottom: "1px solid #dbc4a9" }}>
                                                    {item.Supp_cd}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            )}

                            {showSearchh && (
                                <div className="mb-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '32%', position: 'relative' }}>
                                    <input type="text" placeholder="Item CD" value={latestItem ? latestItem.Supp_cd : ''} style={{ width: "100%", border: "1px solid #dbc4a9", padding: "0.25rem" }} />
                                </div>
                            )}

                            <div className="row">
                                <div className="col-md-4 mb-2"><label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Supplier Name</label>
                                    <input type="text" className="form-control" name="Supp_Nm" value={formData.Supp_Nm} onChange={handleInputChange} maxLength="30" disabled={formMode === 'view'} />
                                </div>
                                <div className="col-md-8 mb-2"><label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Supplier Address</label>
                                    <input type="text" className="form-control" name="Supp_Address" value={formData.Supp_Address} onChange={handleInputChange} maxLength="200" disabled={formMode === 'view'} />
                                </div>
                            </div>
                            <div className="mt-3 row">
                                <div className="col-md-4 mb-2"><label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Contact Person</label>
                                    <input type="text" className="form-control" name="Contact_Person" value={formData.Contact_Person} onChange={handleInputChange} disabled={formMode === 'view'} />
                                </div>
                                <div className="col-md-4 mb-2"><label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Contact Person Phone</label>
                                    <input type="tel" className="form-control" name="Contact_Person_Ph" value={formData.Contact_Person_Ph} onChange={handleInputCPChangee} disabled={formMode === 'view'} />
                                </div>
                                <div className="col-md-4 mb-2"><label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Contact Person Email</label>
                                    <input type="email" className="form-control" name="Contact_Person_Email" value={formData.Contact_Person_Email} onChange={handleInputChange} maxLength="50" disabled={formMode === 'view'} />
                                </div>
                                <div className="col-md-4 mb-2">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Credit Days</label>
                                    <input type="text" className="form-control" name="Credit_Days" value={formData.Credit_Days} onChange={handleInputCreditChangee} disabled={formMode === 'view'} />
                                </div>
                                <div className="col-md-4 mb-2">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>TIN</label>
                                    <input type="text" className="form-control" name="TIN" value={formData.TIN} onChange={handleInputTinChangee} disabled={formMode === 'view'} />
                                </div>
                                <div className="col-md-4 mb-2">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>PAN</label>
                                    <input type="text" className="form-control" name="PAN" value={formData.PAN} onChange={handleIfscPanInputChange} disabled={formMode === 'view'} />
                                </div>
                                <div className="col-md-4 mb-2">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>TDS Section</label>
                                    <input type="text" className="form-control" name="TDS_Section" value={formData.TDS_Section} onChange={handleInputTdsChangge} disabled={formMode === 'view'} />
                                </div>
                                <div className="col-md-4 mb-2">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Bank Account Number</label>
                                    <input type="text" className="form-control" name="Bank_Acc_No" value={formData.Bank_Acc_No} onChange={handleInputBankChangee} disabled={formMode === 'view'} />
                                </div>
                                <div className="col-md-4 mb-2">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Bank Branch</label>
                                    <input type="text" className="form-control" name="Bank_Branch" value={formData.Bank_Branch} onChange={handleBankBranchInputChange} disabled={formMode === 'view'} />
                                </div>
                                <div className="col-md-4 mb-2">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>IFSC</label>
                                    <input type="text" className="form-control" name="IFSC" value={formData.IFSC} onChange={handleIfscInputChange} disabled={formMode === 'view'} />
                                </div>
                                <div className="col-md-4 mb-2">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>GST Registration</label>
                                    <select className="form-control" name="Gst_Reg" value={formData.Gst_Reg} onChange={handleInputChange} disabled={formMode === 'view'}>
                                        <option value="Y">Y</option>
                                        <option value="N">N</option>
                                    </select>
                                </div>
                                <div className="col-md-4 mb-2">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>GST No</label>
                                    <input type="text" className="form-control" name="Gst_No" value={formData.Gst_No} onChange={handleInputChange} maxLength="12" disabled={formMode === 'view'} />
                                </div>
                            </div>
                            <div className="p-3"></div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Suppliermst;








